<template>
  <v-row class="bbr-programs--archived">
    <v-col v-if="hasPrograms || isSearching" cols="12">
      <div class="form-container d-flex align-center">
        <div class="search-form">
          <v-text-field
            flat
            hide-details
            solo
            rounded
            clearable
            label="Search program"
            style="width:400px"
            v-model="searchKey"
            @input="searchChange"
          />
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <programs-list-table
        :is-searching="isSearching"
        :programs="programs"
        :loading="loading"
        :meta="listMeta"
        list-for="Archived"
        @loadMore="loadMorePrograms"
        @goTo="goTo"
      />
    </v-col>
  </v-row>
</template>

<script>
import ProgramsListTable from '../components/ProgramsListTable'
import { mapMutations, mapState, mapActions } from 'vuex'
import Event from '@/services/eventBus'
import debounce from 'lodash/debounce'

export default {
  name: 'ArchivedPrograms',

  components: {
    ProgramsListTable,
  },

  data() {
    return {
      loading: false,
      searchKey: null,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.programs.filter,
      programs: (state) => state.programs.archived.list,
      listMeta: (state) => state.programs.archived.listMeta,
    }),

    hasPrograms() {
      return !!this.programs.length
    },

    isSearching() {
      return !!this.searchKey
    },
  },

  created() {
    this.setFilter({ completed: true, archived: true })

    if (this.filter.completed) {
      this.searchKey = this.filter.search
    }

    if (this.searchKey) {
      this.fetchPrograms()
    }

    Event.$on('update-archived-programs-list', () => {
      this.setFilter({ completed: true, archived: true })
      this.clearList()
      this.fetchPrograms()
    })
  },
  methods: {
    ...mapActions({
      getPrograms: 'programs/getPrograms',
    }),

    ...mapMutations({
      setFilter: 'programs/setFilter',
      clearList: 'programs/clearList',
    }),

    searchChange(key) {
      this.setFilter({
        search: key,
        completed: true,
        archived: true,
      })

      this.searchProgram()
    },

    searchProgram: debounce(function() {
      this.clearList()
      this.fetchPrograms()
    }, 550),

    async fetchPrograms(page = 1) {
      if (this.loading) return

      let search = this.searchKey
      let completed = 1
      let archived = 1
      let params = { page, search, completed, archived }

      this.loading = true

      await this.getPrograms(params)

      this.loading = false
    },

    loadMorePrograms() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchPrograms(this.listMeta.current_page + 1)
      }
    },

    goTo(programId) {
      this.$router.push({ name: 'program.details', params: { id: programId } })
    },
  },
}
</script>
